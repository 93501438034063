import gsap from 'gsap';
import './index.scss';

var data = {
  running: false,
  index: 0,
  cpath: window.location.href + '/assets/creative/',
  container: (path, label, w, h) => {
    var container = document.createElement('div');
    var sect = document.createElement('section');
    sect.id = 'in';
    var frame = document.createElement('iframe');
    var title = document.createElement('h2');
    title.innerText = label;
    frame.src = data.cpath + path + '/index.html';
    frame.setAttribute('width', w);
    frame.setAttribute('height', h);
    frame.setAttribute('frameborder', 0);
    container.appendChild(frame);
    container.appendChild(title);
    sect.appendChild(container);
    return sect;
  },
  creative: [
    ['LoveVictor', 'Love, Victor - Light Animation', 970, 250],
    ['Legion', 'Legion I (Interactivity)', 970, 250],
    ['Legion_2', 'Legion II (Interactivity)', 970, 250],
    ['CloseEnough', 'Close Enough I (Interactivity)', 970, 250],
    ['CloseEnough_dtn', 'Close Enough II (Interactivity)', 970, 250],
    ['LFE', 'Little Fires Everywhere', 970, 250],
    ['Fargo', 'Fargo - Progressive Loading ', 300, 250],
    ['FullFrontal', 'Full Frontal with Samantha Bee (FYC)', 970, 250],
    ['Humana', 'Humana', 300, 250]
    // ['LastOG', 'The Last OG', 728, 90],
    // ['UncleBuck', 'Uncle Buck', 970, 250],
    // ['NYSM2', 'Now You See Me 2 (Interactivity)', 970, 250],
  ],
  lastKey: 0,
};
let changeGradient = function () {
  document.querySelector('#logo').className = `gradFill${data.index}`;
  // document.querySelector('#logo').className = `gradFill${Math.floor(Math.random() * 13)}`;
};
let updateNav = function () {
  document.getElementsByTagName('nav')[0].className =
    data.index == 0 ? 'first' : data.index >= data.creative.length - 1 ? 'last' : '';
};
let cleanup = function () {
  data.running = false;
  var el = document.getElementById('current');
  var newEl = document.querySelector('#in');
  if (el) el.parentNode.removeChild(el);
  newEl.id = 'current';
  document.body.classList.remove('transition');
};
let nextBanner = function (newVal) {
  if (data.running) return;

  data.running = true;
  document.body.classList.add('transition');
  var outdir = newVal < data.index ? '100%' : '-100%';
  var indir = newVal < data.index ? '-100%' : '100%';
  var tl = gsap.timeline({ paused: true, onComplete: cleanup });
  var newEl = data.container(...data.creative[newVal]);
  document.getElementById('main').appendChild(newEl);
  newEl = document.getElementById('in');
  tl.to('#current', 1.1, { y: outdir, ease: 'power4.out' }).from(newEl, 1.1, { y: indir, ease: 'power4.out' }, 0);

  tl.play();
  data.index = newVal;
  console.log(data.index);
  changeGradient();
  updateNav();
  onResize();
};
var checkValue = function (val) {
  return val > data.creative.length || val < 0 ? 0 : val;
};
var onResize = function () {
  let el = document.querySelector('#main').getElementsByTagName('div');
  let scl = Math.min(1.0, window.innerWidth / 1100, window.innerHeight / 700);
  // el.style.transform = 'scale(' + scl + ',' + scl + ') translateX(6.5%)';
  gsap.set(el, { scale: scl, translateX: '-50%', translateY: '-60%', force3D: true });
};

var init = function () {
  nextBanner(data.index);

  document.getElementById('next').addEventListener(
    'click',
    ((evt) => {
      nextBanner(checkValue(data.index + 1));
      evt.preventDefault();
    }).bind(this)
  );
  document.getElementById('prev').addEventListener(
    'click',
    ((evt) => {
      nextBanner(checkValue(data.index - 1));
      evt.preventDefault();
    }).bind(this)
  );
  document.body.addEventListener(
    'keydown',
    ((evt) => {
      let char = evt.keyCode;
      if (/38|40/i.test(char)) {
        evt.preventDefault();
        evt.stopPropagation();

        switch (char) {
          case 38: //up =
            nextBanner(checkValue(data.index - 1));
            break;

          case 40: //down =
            nextBanner(checkValue(data.index + 1));

            break;
          default:
            break;
        }
      }
      data.lastKey = char;
    }).bind(this)
  );
  window.addEventListener(
    'resize',
    (() => {
      onResize();
    }).bind(this)
  );
};
// init();
document.addEventListener('DOMContentLoaded', init);
